import { createContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

// styles
import styles from './content-elements.module.scss';

// types
import type { ContentElementPayload } from './content-elements-payload';

// components
import { contentElements } from './contentElements.env';
import { contentElementsGdds } from './contentElements.gdds';
import { ComponentNotFound } from './ComponentNotFound';
import { ComponentHasError } from './ComponentHasError';
import { Wrapper } from './Wrapper';

// utils
import { isEmptyElement } from './is-empty-element';
import { ContentAreaPlaceholder } from '../ContentArea/ContentAreaPlaceholder';
import { useGdds } from 'utils/hooks/use-gdds';
import { useIsPreview } from 'utils/hooks/useIsPreview';

const ContainerContext = createContext<boolean | null>(null);

const FIXED_CONTENT_SECTION = ['full_grid_image'];

const getElement = (isGdds, type) =>
  isGdds && contentElementsGdds[type] ? contentElementsGdds[type] : contentElements[type];

// workaround until backend fixed the way they send frontend titleInAnchor
function normalizeTitleInAnchor(titleInAnchorRaw?: string | boolean) {
  let titleInAnchor = Boolean(titleInAnchorRaw);
  if (titleInAnchorRaw === 'false') {
    titleInAnchor = false;
  }
  return titleInAnchor;
}

// this type cannot inherit from SectionBaseProps since the server props are not standardized
type ContentElementProps = Partial<ContentElementPayload> & {
  type: string;
  contentIndex: number;
  contentLength: number;
  isChild?: boolean;
  isInSubscription?: boolean;
  isSectionReference?: boolean;
};

export function ContentElement({ isChild = false, ...data }: Readonly<ContentElementProps>) {
  const { type, isInSubscription = false, isSectionReference = false } = data;

  const isGdds = useGdds();
  const isPreview = useIsPreview();

  if (FIXED_CONTENT_SECTION.includes(type) && !isChild) return null;

  const element = getElement(isGdds, type);
  const Component = element ? element.component : null;
  const isContainer = Component ? Boolean(element.isContainer) : null;
  const previewId = data?.previewId;
  const showPlaceholder = isPreview && isEmptyElement(data as ContentElementPayload);
  const titleInAnchor = normalizeTitleInAnchor(data.titleInAnchor);

  return Component ? (
    <ErrorBoundary
      fallback={<ComponentHasError previewId={previewId} isPreview={isPreview} type={data.type} />}
    >
      <ContainerContext.Consumer>
        {(value) => (
          <Wrapper
            isContainerChild={value ?? false}
            previewId={previewId}
            data-is-in-subscription={isInSubscription ? 'true' : 'false'}
          >
            <ContainerContext.Provider value={isContainer}>
              {isInSubscription && isPreview && <div className={styles.masterIcon}>Section</div>}
              {showPlaceholder ? (
                <ContentAreaPlaceholder contentType={data.type} />
              ) : (
                <Component
                  {...data}
                  isChild={isChild}
                  titleInAnchor={titleInAnchor}
                  isContainerChild={value ?? false}
                  isSectionReference={isSectionReference}
                  isInSubscription={isInSubscription}
                />
              )}
            </ContainerContext.Provider>
          </Wrapper>
        )}
      </ContainerContext.Consumer>
    </ErrorBoundary>
  ) : (
    <ComponentNotFound {...data} />
  );
}
